<template>
  <vuestic-widget :loading="loading" class="automation-create-page">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="title">Google Business Messaging</span>
        <div class="d-flex justify-content-end align-items-center">
        </div>
      </div>
    </template>
    <ValidationObserver 
      v-slot="{ handleSubmit, invalid, dirty }"
      ref="userCreateForm"
    >
      <form @submit.prevent="handleSubmit(onFormSubmit)" class="departmentCreateForm">
        <div class="row">
          <div class="col-md-12">
            <text-input name="agentId" v-model="data.agentId" label="Agent ID" validate="required" :disabled="true" />
          </div>
          <div class="col-md-12">
            <text-input name="name" v-model="data.name" label="Displya Name" validate="required" :disabled="true" />
          </div>
          <div class="col-md-12 mt-4">
            <textarea-input name="welcomeMsg" label="Welcome Message" v-model="data.welcomeMsg" :required="true" max-length="255"/>
          </div>
          <div class="col-md-12">
            <text-input name="policyUrl" v-model="data.policyUrl" label="Policy URLe" validate="required" />
          </div>
          <div class="col-md-12 mt-4">
            <span><b>Status:</b> {{ data.statusLabel }}</span>
          </div>
          <div class="col-md-12 mt-4">
            <button class="btn btn-primary" :disabled="invalid || processing" style="min-width: 80px;">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" style="margin: auto;"/>
              <span v-else>Update</span>
            </button>
          </div>

        </div>
      </form>
    </ValidationObserver>
  </vuestic-widget>
</template>

<script>
  import Vue from 'vue';
  import { mapGetters, mapState } from 'vuex';

  export default {
    components: { 
    },
    data() {
      return {
        data: {
        },
        gmbLocation: null,
        loading: false,
        processing: false,
      };
    },

    computed: {

    },
    mounted() {
      if (this.$route.params.id) {
        const id = this.$route.params.id
        this.loadData(id);
      } else {
        this.redirectOnError()
      }
    },
    methods: {
      redirectOnError() {
        this.$router.push({ name: 'business.setting' });
      },
      
      loadData(id) {
        this.loading = true;
        this.$store
          .dispatch('businessIntegration/googleLocationGet', id)
          .then((res) => {
            this.loading = false;
            this.gmbLocation = res
            this.data = {
              agentId: res.agent.agent_id,
              name: res.agent.name,
              welcomeMsg: res.agent.welcome_msg,
              policyUrl: res.agent.policy_url,
              statusLabel: res.agent.status_label,
            }
          })
          .catch((err) => {
            this.loading = false;
          })
      },

      onFormSubmit() {
        const param = {
          id: this.gmbLocation.id, 
          data: this.data
        }
        this.processing = true;
        this.$store
          .dispatch('businessIntegration/googleLocationUpdate', param)
          .then((res) => {
            this.processing = false;
          })
          .catch((err) => {
            this.processing = false;
          })
      },
    },

  };
</script>

<style lang="scss" scoped>
  .departmentCreateForm {
    max-width: 1024px;
  }
</style>